<template>
  <transition
    name="slide-fade"
    mode="out-in"
  >
    <img
      :key="tempdata.chart_base64"
      :src="tempdata.chart_base64"
      :alt="tempdata.player_id"
      class="w-full rounded-sm shadow-md"
    />
  </transition>
</template>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(-10px);
  opacity: 0;
}
</style>

<script>
export default {
  name: "TempLineChart",
  components: {},
  props: ["tempdata"],
  mounted() {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>