<template>
  <div class="h-full w-full">
    <div
      v-if="hasError"
      class="min-h-full min-w-full flex bg-gray-500"
    >
      <div class="flex mx-auto my-auto text-red-400 flex-row">
        <p class="font-normal text-2xl">
          <span class="font-extrabold text-6xl">ERROR</span>
          Please log out and back in
        </p>
      </div>
    </div>
    <div
      v-else
      class="h-full flex flex-col px-0 pt-2 pr-2 pb-4"
    >
      <div class="bg-red-400 font-normal text-white px-1 mt-2 rounded-sm rounded-b-none mb-1 justify-between flex">
        <div class="flex my-auto ml-1">
          -3 days => now
          <HashLoader
            :size="20"
            v-show="loading"
            class="px-4"
            :color="`#FFFFFF`"
          />
        </div>
        <div>
          <button
            class="flex font-normal px-2 mr-1 border border-gray-50 my-2 rounded-sm"
            v-on:click.prevent="switchAutoUpdate()"
          >
            {{
              !enableAutoUpdate ? "ENABLE:AUTO_UPDATE" : "DISABLE:AUTO_UPDATE"
            }}
          </button>
        </div>
      </div>
      <div class="flex-row px-2 pt-2 leading-7 flex-1 overflow-y-scroll border border-gray-50 bg-gray-100 rounded-sm rounded-t-none">
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-1 gap-y-1">
          <TempLineChart
            v-for="tempdata in ALL_TEMPS"
            v-bind:key="tempdata.player_id"
            v-bind:tempdata="tempdata"
            class="min-w-full"
          ></TempLineChart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { HashLoader } from "@saeris/vue-spinners";
import TempLineChart from "./components/TempLineChart";
export default {
  name: "Temperatures",
  components: { HashLoader, TempLineChart },
  beforeMount() {
    this.USER_OBJECT = JSON.parse(localStorage.getItem("USER_OBJECT"));
    this.$store.commit("UPDATE_USER_OBJECT", this.USER_OBJECT);
    this.AUTH_HEADER = JSON.parse(localStorage.getItem("AUTH_HEADER"));
    this.$store.commit("UPDATE_AUTH_HEADER", this.AUTH_HEADER);
    axios.defaults.headers.common["Authorization"] = this.AUTH_HEADER;
  },
  mounted() {
    this.UpdateTemperatures();
  },
  beforeDestroy() {
    clearInterval(this.temperaturesUpdater);
  },
  data() {
    return {
      loading: true,
      enableAutoUpdate: true,
      hasError: false,
      USER_OBJECT: {},
      AUTH_HEADER: "",
      ALL_TEMPS: {},
      temperaturesUpdater: null,
    };
  },
  computed: {},
  methods: {
    switchAutoUpdate: function () {
      if (this.enableAutoUpdate) {
        clearInterval(this.temperaturesUpdater);
        this.enableAutoUpdate = false;
      } else {
        this.UpdateTemperatures();
        this.enableAutoUpdate = true;
      }
    },
    UpdateTemperatures: function () {
      let that = this;
      this.GET_ALL_TEMPERATURES();
      this.temperaturesUpdater = setInterval(function () {
        that.GET_ALL_TEMPERATURES();
      }, 1000 * 60 * 5);
    },
    GET_ALL_TEMPERATURES: function () {
      this.loading = true;
      this.$store.dispatch("GET_ALL_TEMPERATURES", {}).then(
        (response) => {
          this.loading = false;
          this.ALL_TEMPS = response.data.sort(
            (a, b) => b.last_temp - a.last_temp
          );
        },
        (error) => {
          if (error.response.status >= 400 && error.response.status <= 499) {
            this.loading = false;
            this.hasError = true;
            localStorage.removeItem("USER_OBJECT");
            localStorage.removeItem("AUTH_HEADER");
            this.$store.commit("UPDATE_USER_OBJECT", null);
            this.$store.commit("UPDATE_AUTH_HEADER", null);
            this.$router.push("/");
          } else if (
            error.response.status >= 500 &&
            error.response.status <= 599
          ) {
            alert("Internal Server Error :(");
          }
        }
      );
    },
  },
};
</script>
